import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w07GL04Kinderbuch = () => (
  <Layout>
    <SEO title="w07GL04Kinderbuch" />

    <h1 id="ueberschrift">Gemeinsam Lesen</h1>
    <h2>Kinderbücher des Vorschulalters &emsp;  ©&nbsp;1998</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w07GL04Kinderbuch&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Bei dem Versuch einen Text laut (vor-)zu lesen scheint sich manchmal die Zunge verknoten zu wollen.
        Zum Teil weigern sich die Wörter den Mund zu verlassen und dann wieder wollen alle auf einmal ans Tageslicht.
        Und dann gibt es noch das Phänomen, dass die Buchstaben über die Seiten tanzen und sich nur schwer entziffern lassen.
        Dabei ist die Kunst des lauten Lesens in mehrerlei Hinsicht hilfreich.
      </p>
      <p>
        Meist ist es einfacher etwas zu lernen, wenn es mit anderen gemeinsam geübt werden kann.
        Aus diesem Grund bietet dieser Kurs die Möglichkeit
        bei einem gemütlichen Beisammensein das Gewünschte zu trainieren. Genießen Sie die Zeit
        beim gemeinsamen Lesen und "Nachspielen" diverser Kinderbücher. 
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w07GL04Kinderbuch
